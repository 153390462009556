import * as React from "react"

import PickerScreen from '../../../components/pickerScreen'


const Picker = ({ data }) => (
  <PickerScreen
    question="What do you want to design?"
    gridify={true}
    options={[
      {
        label: "A mobile app",
        link: "/app/digital-product-design/mobile-app/"
      },
      {
        label: "A website",
        link: "/app/digital-product-design/website/"
      },
      {
        label: "A web app",
        link: "/app/digital-product-design/web-app/"
      },
      {
        label: "A desktop software",
        link: "/app/digital-product-design/desktop-software/"
      }
  ]}/>
)

export default Picker;


